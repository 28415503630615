.fade-content {
  height: 0px;
  margin: 0 0 50px 0;
 
  position: relative;
  overflow: hidden;
  visibility: hidden;

  @include media-breakpoint-up(lg) {
    height: 100%;
    margin: auto;
    visibility: visible;
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(transparent -50px, white);

    @include media-breakpoint-up(lg) {
      content: none;
    }
  }

  p 
  {
    a[href]
    {
      color: black;
      text-decoration:underline;
      // min-width: 44px;
      // min-height: 44px;
      // display: inline-flex;
      // align-items: center;
    }
  }

  td 
  {
    a[href]
    {
      color: black;
      text-decoration:underline;
      // min-width: 44px;
      // min-height: 44px;
      // display: inline-flex;
      // align-items: center;
    }
  }
  
  H3 
  {
    a[href]
    {
      color: black;
      text-decoration:underline;
      // min-width: 44px;
      // min-height: 44px;
      // display: inline-flex;
      // align-items: center;
    }
  }

  li 
  {
    a[href]
    {
      color: black;
      text-decoration:underline;
      // min-width: 44px;
      // min-height: 44px;
      // display: inline-flex;
      // align-items: center;
    }
  }
}

#fadeButton {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

