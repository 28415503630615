@import '../utilities/rem-calculator';

.carousel {
  margin-top: 30px;
  margin-bottom: 30px;

  .carousel-indicators {
    left: auto;
    margin: 0;
    height: 47px;
    background-color: $black;

    li {
      text-align: center;
      height: auto;
      text-indent: inherit;
      margin-left: 0;
      background-clip: border-box;
      display: none;
      margin: 0;
      height: 30px;
      color: $carousel-text-color;
      background-color: $secondary-color;

      &.active {
        display: block;
      }

      &.total,
      &.divider {
        display: block;
        opacity: 1;
      }

      &.divider {
        width: 7px;
      }
    }
  }

  .carousel-caption {
    position: relative;
    top: 0;
    left: 0;
    right: auto;
    min-height: 47px;
    max-height: 110px;
    padding: 10px;
    margin: 0;
    text-align: left;
    width: 100%;
    background-color: $secondary-color;
  }
    
  .text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    width: 90%;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    //white-space: nowrap;
    color: $carousel-text-color;
  }
}

.icons-navigate-before{
  //font-size: 50px;
  font-size: em(50);
  font-weight: bold;
  &:hover{
    color: $white;
    opacity: 1;
  }
}

.icons-navigate-next{
  //font-size: 50px;
  font-size: em(50);
  font-weight: bold;
  &:hover{
    color: $white;
    opacity: 1;
  }
}

.carousel-control-prev{
  opacity: 1 !important;
}

.carousel-control-next{
  opacity: 1 !important;
}

.carousel-control-prev:focus,
.carousel-control-next:focus {
  outline: 3px solid black; 
}