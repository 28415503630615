// Theme specific variables and styles go in this file.

// Theme specific variables
$primary-color: #002855;
$secondary-color: #f5821e;

$logo: '/UI/dist/assets/FMN_Design_logoer/FMN_Graa_RGB.png';

$link: #f5821e;
$indexModule: #002855;

$carousel-text-color: #000000;

.nav-link {
    &[aria-expanded="true"] {
      //border-bottom: 2px solid $link !important;
      text-decoration: underline 2px $link !important;
      text-underline-offset: 10px !important;
    }
  }


// Import basic theme
@import "../main.scss";
