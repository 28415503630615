/* .quotes {
  font-weight: bold;
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: -1.2px;
  padding: 25px 0;
}

ul {
  &.list {
    margin-bottom: 15px;

    li {
      margin-bottom: 15px;
    }
  }
} */

.editor-content{
  p 
  {
    a[href]:first-child
    {
      color: black;
      text-decoration:underline;
      min-width: 24px;
      min-height: 24px;
      display: inline-flex;
      align-items: center;
    }
    a[href]
    {
      color: black;
      text-decoration:underline;
      min-width: 24px;
      min-height: 24px;
      display: inline-flex;
      align-items: center;
    }
  }

  td 
  {
    a[href]
    {
      color: black;
      text-decoration:underline;
      min-width: 24px;
      min-height: 24px;
      display: inline-flex;
      align-items: center;
    }
  }
  
  H3
  {
    a[href]
    {
      color: black;
      text-decoration:underline;
      min-width: 24px;
      min-height: 24px;
      display: inline-flex;
      align-items: center;
    }
  }

  li 
  {
    a[href]
    {
      color: black;
      text-decoration:underline;
      min-width: 24px;
      min-height: 24px;
      display: inline-flex;
      align-items: center;
    }
  }
}