@import '../utilities/rem-calculator';

.noiseBanner {
  position: fixed;
  width: 100%;
  height: auto;
  z-index: 1000;
  bottom: 0px;
  left: 0px;
  padding: 20px 0px 0px 0px;
  background-color: $secondary-color;
  color: $white;

  .container:nth-of-type(2) {
    overflow: auto;
    height: 100%;
    display: none;
  }

  .header-text {
    color: $white;

    @include media-breakpoint-up(md) {
      //@include fontSize(20px);
      font-size: em(20);
    }
  }

  .btn-close {
    width: 100%;

    .fa {
      color: $white;
    }
  }

  .noise-item {
    display: flex;
    flex-direction: column;

    .link.link-secondary {
      color: $white;
    }

    div:nth-child(n) {
      margin: 0 0 35px 0;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;

      div:nth-child(n + 2) {
        margin: 0 0 0 40px;
      }
    }
  }
}
