@import '../utilities/rem-calculator';

.title-section {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media-breakpoint-down(md) {
    h1 {
      font-size: em(24);
      //@include fontSize(24px);
    }
  }
}
