fb_iframe_widget_fluid_desktop {
  max-width: 100% !important;
  width: 100% !important;

  span,
  iframe {
    max-width: 100% !important;
    width: 100% !important;
  }
}
