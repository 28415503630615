.content {
  .person-list {
    .list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      list-style-type: none;
      padding-left: 0px;

      .item {
        display: flex;
        flex-direction: column;
        padding: 30px 0px;
        border-bottom: 1px solid $seperator-grey;

        @include media-breakpoint-down(sm) {
          padding: 30px 10px;
        }
       
        a {
          color: $black;

          &:hover {
            text-decoration: none;
          }

          .image {
            @include media-breakpoint-down(sm) {
              width: 100%;
            }
          }

          .title {
            font-weight: bold;
            margin-bottom: 10px;
            margin-right: 10px;
          }

          h2.title {
            font-family: Verdana, Geneva, Tahoma, sans-serif;
            @include fontSize($base-size);
            line-height: 1.5;
            letter-spacing: -0.5px;
            line-break: loose;
            font-weight: bold;
            margin-bottom: 10px;
            margin-right: 10px;
          }

          .department {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
