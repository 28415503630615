.seperator {
  margin: 0 0 35px 0;

  &--green {
    width: 100%;
    border: 5px solid rgba($color: $secondary-color, $alpha: 1);
    background-color: rgba($color: $secondary-color, $alpha: 1);
  }

  &--grey {
    border: 0.5px solid rgba($color: $seperator-grey, $alpha: 1);
    background-color: rgba($color: $seperator-grey, $alpha: 1);;

    @include media-breakpoint-down(lg) {
      margin: 0 0 20px 0;
    }
  }

  &--green,
  &--grey {
    &--0 {
      margin: 0;
    }

    &--10 {
      margin: 10px 0;
    }

    &--30 {
      margin: 30px 0;
    }

    &--60-35 {
      margin: 60px 0 35px 0;
    }

    &--60 {
      margin: 60px 0;
    }

    &--10-60 {
      margin: 10px 0 60px 0;
    }

    &--5-10 {
      margin: 5px 0 10px 0;
    }
  }

  &--vertical {
    border-left: 1px solid $seperator-grey;
    height: 100%;
    margin-left: 50%;

    @include media-breakpoint-down(md) {
      border-left: none;
      border-top: 1px solid $seperator-grey;
      margin: 30px 0 60px 0;
    }
  }
}