h1{
     @include media-breakpoint-down(md){
         //font-size: 30px;
         font-size: em(30);
         line-height: 1.5;
     }
}

h2 {
    &.titel {
        @include media-breakpoint-down(md){
            word-break: break-word;
            //font-size: 30px;
            font-size: em(30);
        }
    }
  }

h3{
    @include media-breakpoint-down(md){
      @include headline-3-mellemoverskrift;
    }
}

h5{
    @include media-breakpoint-down(md){
        //font-size: 20px;
        font-size: em(20);
        line-height: 1.5;
    }
}

p{
    @include media-breakpoint-down(md){
        //font-size: 16px !important;
        font-size: em(16) !important;
        //line-height: calculateRem(27px) !important;
        line-height: 1.5 !important;

    }


}

.image-text
{
    @include media-breakpoint-down(md){
        //font-size: 13px !important;
        font-size: em(13) !important;
        //line-height: calculateRem(16px) !important;
        line-height: 1.5 !important;
        margin-bottom: 30px;
    }
}


