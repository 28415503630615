input.form-control {
  border: 2px solid $border;
  height: 41px;
  border-radius: 0;
  
  &:focus,
  &.focus {
    box-shadow: none;
    outline: none;
    border-color: black;
  }

  &:focus:not(:focus-visible){
    box-shadow: none;
    outline: none;
  }

  &:focus-visible{
    box-shadow: none;
    border-color: black;
    outline: 2px solid black !important;
  }
}
