.related-content,
.news-list {
  .info {
    @include small-text;
    margin-bottom: 10px;

/*     .date,
    .category,
    .authority {
      //@extend .info;
    } */

    .auth{
      p {
        padding-top: 5px;
      }
    }

    .authority {
      padding: 5px;
      color: $white;

      &--forsvarsministeriet {
        background-color: $fki-dark-green;
      }

      &--forsvaret {
        background-color: $fki-red;
      }

      &--hjemmeværnet {
        background-color: $fki-red;
      }

      &--forsvarets-efterretningstjeneste {
        background-color: $fki-black;
      }

      &--beredskabsstyrelsen {
        background-color: $fki-orange;
        color: $fki-black;
      }

      &--forsvarsministeriets-auditørkorps {
        background-color: $fki-purple;
      }

      &--hæren {
        background-color: $fki-green;
      }

      &--søværnet {
        background-color: $fki-blue;
      }

      &--søværnet-og-beredskabsstyrelsen {
        background-color: $fki-blue;
      }

      &--flyvevåbnet {
        background-color: $fki-lightblue;
      }


    }
    .date {
      color: $black;
      font-size: $base-size;
    }

    .category {
      color: $black;
      font-size: $base-size;
    }

    .category.seperator {
      color: $black;
    }
  }
}
