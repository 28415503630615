@import '../utilities/rem-calculator';

.world-map-block {
  position: relative;

  .information-container,
  .toggle-view-container {
    position: absolute;
    z-index: 1;
  }

  .information-container {
    top: 20px;
    left: 20px;

    .fa {
      color: $white;
    }
  }

  .toggle-view-container {
    top: 20px;
    right: 15px;
  }

  .world-map {
    position: relative;
    width: 100%;
    background-color: $black;

    .map-box {
      .header {
        display: flex;
        justify-content: space-between;
      }
    }

    .map-box-group {
      .fa-times {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 20px;
        color: $white;
      }
    }

    .map-box,
    .map-box-group {
      position: absolute;
    @include media-breakpoint-down(md){
      top: calc(100% - 100px);
      width: 100%;
    }
    @include media-breakpoint-up(lg){
      width: 500px;
      top: calc(50% - 100px);
    } 
      background-color: $primary-color;
      height: auto;
      padding: 25px;
      left: 0;
      z-index: 1;
      color: $black;

      .description {
        margin-bottom: 15px;
      }

      .link {
        color: $black;
        text-decoration: underline;
      }

      .accordion {
        margin-bottom: 5px;

        .card {
          border-radius: 0;
          background: none;
          border: none;

          .card-header {
            padding: 0 0 5px 0;
            border-radius: 0;
            background: none;
            border-bottom: 1px solid $white;

            .card-title{
              font-size: $base-size;
              font-weight: bold;
              line-height: 1.5;
              letter-spacing: -0.5px;
              margin-block-start: 2rem;
              margin-block-end: 0rem;
            }

            .titel {
              margin: 0;

              .btn.btn-link {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: $white;
                text-decoration: none;
                padding: 5px 0;
                text-align: left;

                .text {
                  word-break: break-word;
                  font-size: em(20);
                  //font-size: 20px;
                  font-weight: bold;
                  width: 90%;
                }

                .fa {
                  position: absolute;
                  right: 0;
                }
              }
            }
          }

          .card-body {
            color: $white;
            padding: 15px 0 0 0;
            border-bottom: 1px solid $border;

            p {
              padding: 15px 0;

              .link {
                color: $white;
              }
            }
          }
        }
      }
    }

    .map-box {
      color: $white;

      .link {
        color: $white;
      }
    }
  }

  .operations {
    display: none;
    margin-top: 0px;

    .container{
      padding-left: 0px;
      margin-top: -30px;
    }

    .country {
      margin: 30px 0;

      .header {
        margin: 30px 0 15px 0;
        border-bottom: 0.5px solid $border-2;
      }
      .item {
        padding: 15px 0;
        border-bottom: 0.5px solid $border-2;

        &:last-child {
          border: none;
        }

        .link {
          display: flex;
          color: $black;

          &:hover {
            color: $black;
          }
        }
      }
    }
  }

  #map_size{
    @include media-breakpoint-down(md){
      height: 400px !important;
    }
    @include media-breakpoint-up(lg){
    height: 1000px;
    }
  }

  .world-btn-destop{
    @include media-breakpoint-down(md){
      display: none;
    }
  }

  .world-btn-mobile{
    @include media-breakpoint-up(lg){
      display: none;
    }
  }


  .popup {
    position: relative;
    display: inline-block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* The actual popup */
  .popuptext {
    visibility: hidden;
    width: 200px;
    max-height: 300px;
    background-color: #fff;
    color: #000;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    top: 125%;
    left: 50%;
    margin-left: -15px;
    word-break: break-word;
    padding-left: 10px;
    padding-right: 10px;
    overflow-x: auto;
  }

  /* Popup arrow */
  .popuptext::after {
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #000 transparent transparent transparent;
  }

  /* Toggle this class - hide and show the popup */
  .show {
    visibility: visible;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
  }

  /* Add animation (fade in the popup) */
  @-webkit-keyframes fadeIn {
    from {opacity: 0;} 
    to {opacity: 1;}
  }

  @keyframes fadeIn {
    from {opacity: 0;}
    to {opacity:1 ;}
  }

  .icons-list{
    font-size: 2em;
    color: $white;
  }

  .icons-public{
  font-size: 2em;
  vertical-align: middle;
  }

  .icons-maps-close{
    color: $white;
    text-align: end;
  }

  .information-container{
    .icons-info{
      color: $white;
    }
  }

}