@import '../utilities/rem-calculator';

.social-media {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  padding: 20px 0;
  border-top: 1px solid $seperator-grey;
  border-bottom: 1px solid $seperator-grey;

  .list {
    display: flex;

    .item {
      margin-right: 32px;
    }

    .facebook,
    .twitter,
    .linkedin,
    .bluesky {
      @extend %fa-icon;
      @extend .fab;

      a {
        color: $black;
        text-decoration: none;
        min-width: 44px;
        min-height: 44px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
    }

    .icon {
      //@include fontSize(26px);
      font-size: em(26);
      vertical-align: top;
      margin-right: 10px;
    }

    .somesharing{
      vertical-align: -webkit-baseline-middle;
    }
  }

  .privacy {
    margin: 10px 0;
    @include small-text;
    color: $black;
  }
}

h3{
  &.twitter-title{
    @include headline-3;
  }
}

h2{
  &.twitter-title-vertical{
    @include headline-3;
  }
}

h2{
  &.twitter-title-horizontal{
    @include headline-2;
  }
}

.fb-root{
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  font-size: $base-size !important;

}

h2.facebook-title{
  @include headline-3;
}

h2.modal-title{
  @include headline-4;
}

