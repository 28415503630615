@import '../utilities/rem-calculator';

.footer {
  &--primary {
    margin-bottom: 63px;

    .logo {
      height: 45px;
      margin-bottom: 40px;
    }

    .block {
      @include media-breakpoint-down(md) {
        margin-bottom: 20px;
      }
    }

    h2.title {
      @include headline-3-manchet-bold;
      margin: 0 0 18px 0;
    }

    .title {
      @include headline-3-manchet-bold;
      margin: 0 0 18px 0;
    }
    
    .description {
      @include regular-text-spacing;
    }

    .contact {
      display: flex;
      flex-direction: column;

      .item {
        margin: 0 0 20px 0;

        &:last-child {
          margin: 0;
        }
      }

      .item-last{
        margin: 0;
      }

      .link-contactemail{
        display: inline-flex;
        align-items: flex-start;
      }
    }

    .links {
      width: 100%;
      list-style-type: none;
      padding: 0;

      .item {
        margin: 0 0 0px 0;

        .fa,
        .fab {
          //font-size: 22px;
          font-size: em(22);
          margin-right: 16px;
        }

        .link {
          color: $black;
          text-decoration: none;
          //vertical-align: middle;
          min-width: 44px;
          min-height: 44px;
          display: flex;
          align-items: center;
          //padding-bottom: 10px;
          margin-bottom: 5px;

          &:hover {
            color: $link;
            text-decoration: none;
          }
        }

        .link-some{
          display: inline-block;
        }
      }
    }
  }

  .footer-list {
    background-color: $footer;

    .list{
      .link{
        min-width: 44px;
        min-height: 44px;
        display: flex;
        align-items: center;

      }
    }

    .card {
      border: none;
      background-color: $footer;
      padding: 5px 0 10px 0;

      .card-header {
        margin: 0;
        border-radius: 0;
        border: none;
        background-color: transparent;
        padding: 0;

        .text {
          @include fontSize(15px);
          //font-size: 0.37em;
          vertical-align: middle;
        }

        .content{
          @include headline-2;
        }

        .btn {
          @include regular-text-bold;
          text-decoration: none;
          color: $black;
          padding: 0;

          min-width: 44px;
          min-height: 44px;
          display: inline-flex;
          align-items: center;

          &:focus {
/*             outline: 0;
            -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); */
            outline: 2px solid black !important;
            margin: 2px;
          }

          .fa-plus,
          .fa-minus {
            color: $link;
            padding-right: 5px;
          }

          .icons-add {
            color: $link;
            padding-right: 5px;
            font-size: 22px;
            //font-size: em(22);
            font-weight: bold;
          }


           &.expanded {
            .content {
              display: none;
            }
            .close-btn {
              display: block;
              position: absolute;
              right: 0;
              min-width: 44px;
              min-height: 44px;
              display: inline-flex;
              align-items: center;
              padding-top: 10px;
            }
            &:focus {
              outline: none !important;
            }
          } 
          .close-btn {
            display: none;
          }
        }
      }

      .card-body {
        margin: 20px 0 0 0;
        padding-left: 0;
        padding-right: 0;

        .section {
          padding: 50px 0 30px 0;
          width: 100%;
          border-top: 1px solid $seperator-grey;

          &:first-child {
            margin: 50px 0 0 0;
            border-bottom: none;
          }

          &:last-child {
            margin-bottom: 60px;
          }

          .icon {
            margin-bottom: 20px;
            width: 30px;

            @include media-breakpoint-down(md) {
              width: 40px;
            }
          }

          .link {
            font-weight: bold;
            //font-size:  0.37em;
            @include fontSize(15px);
          }

          .links {
            order: 1;
            flex: 1 0 100%;

            .link {
              font-weight: bold;
            }

            .list {
              margin: 5px 0;
              padding: 0;
              list-style-type: none;

              .item {
                margin: 0 0 5px 0;

                .link {
                  //font-size:  0.37em;
                  @include fontSize(15px);
                  font-weight: normal;
                }
              }
            }
          }
        }
      }
    }
  }

  .logofooterlink{
    min-width: 44px;
    min-height: 44px;
    display: inline-block;
  }

  .footer-collapse-header{
    word-break: break-word;
    @include headline-2;
  }

  @include media-breakpoint-down(md) {
    .footer-collapse-header{
      //font-size: 25px;
      font-size: em(25);
      //line-height: calculateRem(30px);
      line-height: 1.5;
    }
    .card-body{
      margin: 0px !important;
    }
  }

  .cookie {
    background-color: $primary-color;

    .list {
      padding: 10px 0;
      margin: 0;
      list-style-type: none;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        padding-bottom: 30px;
      }

      .item {
        margin-left: 30px;

        @include media-breakpoint-down(md) {
          margin: 10px 0;
        }

        .link {
          color: $white;
        }
      }
    }
  }
}
