.banner {
  position: relative;
  overflow: hidden;

  @include media-breakpoint-down(lg) {
    overflow: visible;
  }

  .image {
    position: inherit;
    max-height: 500px;

    @include media-breakpoint-down(lg) {
      height: auto;
    }

    img {
      position: relative;
      width: 100%;
      //top: 50%;
      //transform: translateY(-50%);

      @include media-breakpoint-down(lg) {
        transform: none;
        top: auto;
      }
    }
  }

  .align-right {
    .info-box {
      right: 0;
    }
  }

  .info-box {
    position: absolute;
    width: 442px;
    margin: 40px;
    padding: 40px;
    background-color: $primary-color;
    color: $white;
    top: 0%;
    transform: translateY(0%);

    @include media-breakpoint-down(lg) {
      position: relative;
      margin: 0;
     // padding: 20px 15px;
      width: 100%;
    }

    .info-title {
      @include banner-headline;
      color: $white;
      word-break: break-word;
    }

    .info-text {
      margin-top: 25px;

      p {
        word-break: break-word;
      }
    }

    .info-link {
      color: $white;
      text-decoration: underline;
    }
  }
}
