@import '../utilities/rem-calculator';

.pagination {
  margin: 30px 0 0 0;

  .page-item {
    padding: 5px;

    &.active {
      .page-link {
        background-color: transparent;
        //color: $seperator-grey;
        color: black;
        //color: $seperator-green;
        text-decoration: underline;
        text-decoration-thickness: 0.2em;
        text-underline-offset: 3px;
      }
    }

    .page-link {
      font-weight: bold;
      color: $black;
      border: none;
      min-width: 44px;
      min-height: 44px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0;

      &:hover {
        background-color: transparent;
        color: $seperator-grey;
      }

      &:focus{
        box-shadow: 0 0 0 2px black;
      }

      span {
        line-height: 0;

        .fa {
          color: $fki-red;
        }
      }
   
      .icons-arrow-forward{
        color: #c8102e;
        //font-size: em(20);
        font-size:20px ;
        font-weight: bold;
        vertical-align: bottom;
      }
      
      .icons-arrow-back{
        color: #c8102e;
        //font-size: em(20);
        font-size: 20px;
        font-weight: bold;
        vertical-align: bottom;
      }

      .icons-last-page{
        color: #c8102e;
        //font-size: em(24);
        font-size: 20px;
        font-weight: 900;
      }
      .icons-first-page{
        color: #c8102e;
        //font-size: em(24);
        font-size: 20px;
        font-weight: 900;
      }
    }

    .icon{
      padding: 10px 5px 10px 5px;
    }

    &:first-child {
      .page-link {
        border-radius: 0;
      }
    }
    &:last-child {
      .page-link {
        border-radius: 0;
      }
    }
  }
}
