.presentation{
    padding: 0px;
    overflow-x: hidden;

    .image-text{
        margin-bottom: 0px !important;
    }

    .link-list {
        margin: 0;
        .item {
            margin: 0 0 5px 0;
        }
    }
}

.presentation-title{ 
    padding-right: 20px;
    
    .titel {
        word-break: break-word;
        @include headline-2;
    }
}

.scroll-to-anchor{
    visibility: hidden;
    line-height: 0%;
}