.dropdown {
  .dropdown-menu {
    padding: 0;
    border-radius: 0;
    border-color: $border;

    .dropdown-item {
      padding: 6px 1.5rem;

      &:hover,
      &:focus {
        color: $white;
        background-color: $fki-dark-green;
      }
    }
  }

  .dropdown-toggle {
    padding-left: 0px;
  }
}
