@media print{
  .cookie-toggler                                 // Cookie icon
  ,.social-media                                  // Social media sharing
  ,body > div:first-of-type .seperator            // First mandatory seperator
  ,header .navbar .navbar-toggler                 // Menu (Mobile)
  ,header .navbar .navbar-collapse                // Menu (desktop)
  ,#epi-quickNavigator                            // Episerver CMS shortcut
  ,.header .navbar .navbar-brand .logo--sticky    // Small icon (mobile)
  ,main .carousel.slide                           // Carousel
  ,div.hero                                       // Hero
  ,#fadeButton                                    // "Læs mere" knap
  ,footer                                         // Footer
  {display:none !important}
  
  //Handle pagesize and margins
  @page{
    size: A4;
    margin-top: 20mm;
    margin-left: 10mm;
    margin-bottom: 20mm;
    margin-right: 10mm;
  }

  body{
    margin: 0px;
  }

  // Handle icons
  .logo--no-sticky{                               // Always show desktop icon
    display: inline !important;
  }

  .header{
    position: static !important;
  }
  
    // Handle fade-content
    .fade-content {
      height: 100%;
      margin: auto;
  
      &::before {
        content: none;
      }
    }

    // Handle images
    .img-fluid{
      max-width: 80% !important;
      //margin-left: auto !important;
      //margin-right: auto !important;
      //display: block !important;
      height: auto !important;
    }

    p img{
      max-width: 80% !important;
      //margin-left: auto !important;
      //margin-right: auto !important;
      //display: block !important;
      height: auto !important;
    }

    .related-content .img-fluid{                   // Don't resize images in related content
      max-width: 100% !important;
      height: auto !important;
    }

    // Handle factbox and attachments
    .factbox, .attachments
    {
      background-color: #fff !important;
      border:1px solid black;
    }

    .related-content .image-container 
    {
      box-sizing: border-box !important;
      position: relative !important;
      width: 40% !important;

    }
  }
  