@import '../utilities/rem-calculator';

.factbox {
  .country-information {
    .headline {
      @include headline-3;
      padding-bottom: 15px;
    }

    .list {
      list-style-type: none;
      padding: 0;

      .item {
        margin-bottom: 15px;

        .title {
          @include standard;
          font-weight: bold;
          padding: 0;

          span{
            vertical-align: middle;
          }
        }
        
        .countries-list {
          padding: 0;
          list-style-type: none;

          .item {
            margin: 0;
          }
        }

        .description {
          @include regular-text;
          margin: 5px 0 3px 27px;
        }
      }
    }
  }
}

.icons-operation{
  font-size: 24px;
  //font-size: em(24);
  vertical-align: text-bottom;
}
