.table-striped {
  thead {
    tr {
      th {
        &:nth-child(n + 2) {
          display: none;

          @include media-breakpoint-up(md) {
            display: table-cell;
          }
        }
      }
    }
  }

  tbody {
    tr {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(md) {
        display: table-row;
      }

      td {
        &:nth-child(n + 2) {
          display: none;

          @include media-breakpoint-up(md) {
            display: table-cell;
          }
        }
      }
    }
  }
}


table {
  max-width: 100% !important;
  border-collapse: collapse;
  
  @include media-breakpoint-down(md){
    width: 100% !important;
  }
}



tr:nth-of-type(odd) {
  background: #eee;
}


td, th {
  padding: 6px;
  border: 1px solid #ccc;
  text-align: left;
}