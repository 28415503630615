.search-in-header {
  width: 100%;
  background-color: $primary-color;
  margin: 0;

  .search-section > form {
    padding: 15px 0px 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .active {
      border-bottom: 1px solid rgba($color: $border-2, $alpha: 0.7);
    }

    .btn.search {
      min-width: 44px;
      min-height: 44px;
      display: flex;
      align-items: center;
      padding: 0px 10px 0 10px;
    }

    .form-control {
      background-color: transparent;
      color: $white;
      border: none;
      border-radius: 0px;
      padding: 0;

      &:focus {
        box-shadow: none;
      }

      &::placeholder {
        color: $white;
      }
    }

   /*  .fa.fa-search {
      color: $white;
      font-size: 20px;
    }
 */
    .icons-search {
      color: $white;
      font-weight: bold;
    }
  }
}

.navbar-nav {
  .btn.toggleSearch {

      min-width: 44px;
      min-height: 44px;
      display: flex;
      align-items: flex-start;
      padding-top: 0;

    .icons-search{
      width: 17px;
      font-weight: bold;
    }
  }


}

//search in mobile menu
.navbar-nav-mobile{
  .accordion{
    .input-group{
      border: gray solid; 
      border-width: thin; 
      border-bottom: none;

      input{
          width: 77%; 
          float: left; 
          border: none;
        }
        
      button{
          float: right;
        }
    }
  }
}




