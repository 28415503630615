@import "./rem-calculator";

$base-size: em(17);

@mixin standard {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  @include fontSize($base-size);
  //line-height: calculateRem(27px);
  line-height: 1.5;
  letter-spacing: -0.5px;
  line-break: loose;
}

@mixin headline-1 {
  font-weight: bold;
  //@include fontSize(46px);
  font-size: em(46);
  //line-height: calculateRem(54px);
  line-height: 1.5;
  letter-spacing: -2px;
}

.headline-2-seo {
  font-weight: normal;
  //@include fontSize(20px);
  font-size: em(20);
  //line-height: calculateRem(30px);
  line-height: 1.5;
  letter-spacing: 0px;
}

@mixin headline-2 {
  font-weight: bold;
  //@include fontSize(40px);
  font-size: em(40);
  //line-height: calculateRem(54px);
  line-height: 1.5;
  letter-spacing: -2px;
}

@mixin headline-3 {
  font-weight: bold;
  //@include fontSize(28px);
  font-size: em(28);
  line-height: 1.5;
  letter-spacing: -1.2px;
}

@mixin headline-3-manchet {
  //@include fontSize(15px);
  font-size: em(15);
  //line-height: calculateRem(22px);
  line-height: 1.5;
  letter-spacing: -0.4px;
}

@mixin headline-3-manchet-bold {
  //@include fontSize(15px);
  font-size: em(15);
  //line-height: calculateRem(20px);
  line-height: 1.5;
  letter-spacing: -0.3px;
  font-weight: bold;
}

@mixin headline-3-mellemoverskrift {
  font-weight: bold;
  //@include fontSize(20px);
  font-size: em(20);
  line-height: 1.5;
  letter-spacing: -1.2px;
}

@mixin headline-3-lille-overskrift {
  font-weight: bold;
  //@include fontSize(20px);
  font-size: em(18);
  line-height: 1.5;
  letter-spacing: -1.2px;
}

@mixin headline-4 {
  font-size: $base-size;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: -0.5px;
  margin-block-start: 2rem;
  margin-block-end: 0rem;
}

@mixin headline-5-citat {
  //@include fontSize(20px);
  font-size: em(20);
  //line-height:calculateRem(27px);
  line-height: 1.5;
  letter-spacing: -0.5px;
  font-weight: bold;
  width: 70%;
}

@mixin news-headline {
  font-weight: bold;
  //@include fontSize(24px);
  font-size: em(24);
  line-height: 1.5;
  letter-spacing: -1px;
}

@mixin news-list-headline {
  font-weight: bold;
  //@include fontSize(24px);
  font-size: em(24);
  //line-height: calculateRem(29px);
  line-height: 1.5;
  letter-spacing: -1.11px;
}

@mixin news-first-title {
  font-weight: bold;
  //@include fontSize(28px);
  font-size: em(28);
  line-height: 1.5;
  letter-spacing: -1.2px;
  }

@mixin navigation-manchet {
  //@include fontSize(30px);
  font-size: em(30);
  //line-height: calculateRem(42px);
  line-height: 1.5;
  letter-spacing: -1.1px;
}

@mixin navigation-headline {
  font-weight: bold;
  //@at-root fontSize(20px);
  font-size: em(20);
  line-height: 1.5;
  letter-spacing: -0.4px;
}

@mixin navigation-headline-promoted {
  font-weight: bold;
  font-size: em(24);
  line-height: 1.5;
  letter-spacing: -0.4px;
}

@mixin table-of-content {
  font-weight: bold;
  //@include fontSize(20px);
  font-size: em(20);
  //line-height: calculateRem(24px);
  line-height: 1.5;
  letter-spacing: -0.92px;
}

@mixin navigation-description {
 //@include fontSize(18px);
 font-size: em(18);
  //line-height: calculateRem(27px);
  line-height: 1.5;
  letter-spacing: -0.5px;
}

@mixin related-content-title {
  font-weight: bold;
  //@include fontSize(28px);
  font-size: em(28);
  //line-height: calculateRem(34px);
  line-height: 1.5;
  letter-spacing: -1.3px;
}

@mixin related-content-headline {
  font-weight: bold;
  //@include fontSize(18px);
  font-size: em(18);
  //line-height: calculateRem(22px);
  line-height: 1.5;
  letter-spacing: -1.35px;
}

@mixin banner-headline {
  font-weight: bold;
  //@include fontSize(30px);
  font-size: em(30);
  //line-height: calculateRem(38px);
  line-height: 1.5;
  letter-spacing: normal;
}

@mixin content-manchet {
  //@include fontSize(23px);
  font-size: em(23);
  //line-height: calculateRem(34px);
  line-height: 1.5;
  letter-spacing: -0.5px;
}

@mixin regular-text {
  //@at-root fontSize(15px);
  font-size: em(15);
  line-height: 1.5;
}

@mixin regular-text-spacing {
  //@include fontSize(15px);
  font-size: em(16);
  //line-height: calculateRem(22px);
  line-height: 1.5;
  letter-spacing: -0.4px;
}

@mixin regular-text-bold {
  //@include fontSize(15px);
  font-size: em(15);
  //line-height: calculateRem(18px);
  line-height: 1.5;
  letter-spacing: normal;
  font-weight: bold;
}

@mixin small-text {
  //@include fontSize(13px);
  font-size: em(13);
  //line-height: calculateRem(16px);
  line-height: 1.5;
  letter-spacing: -0.4px;
}

@mixin button-text {
  //@include fontSize(15px);
  font-size: em(15);
  font-weight: bold;
  letter-spacing: -0.3px;
  //line-height: calculateRem(17px);
  line-height: 1.5;
  text-align: center;
}

h1 {
  @include headline-1;
}

h2 {
  @include headline-2;
}

p,
li,
span {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  @include fontSize($base-size);
  //line-height: calculateRem(27px);
  line-height: 1.5;
  letter-spacing: -0.5px;
  line-break: loose;
}

p {
  margin-block-end: 0;
}

h2 {
  &.titel {
    word-break: break-word;
    @include headline-2;
  }
}

h4 {
  //@include fontSize($base-size);
  font-size: $base-size;
  font-weight: bold;
  //line-height: calculateRem(27px);
  line-height: 1.5;
  letter-spacing: -0.5px;
  margin-block-start: 2rem;
  margin-block-end: 0rem;
}

h5 {
  padding: 15px;
  font-weight: bold;
  width: 70%;
  //@include fontSize(25px);
  font-size: em(25);
  //line-height: calculateRem(29px);
  line-height: 1.5;
  letter-spacing: -0.74px;
  margin-block-start: 1rem;
  margin-block-end: 1rem;

  &:before,
  &:after {
    content: '"';
  }
}

.h2-subheader{
  @include headline-3-mellemoverskrift;
  word-break: break-word;

  a[href]
  {
    color: black;
    text-decoration:underline;
    // min-width: 44px;
    // min-height: 44px;
    // display: inline-flex;
    // align-items: center;
  }
}

.h3-subheader{
  @include headline-3-lille-overskrift;
  word-break: break-word;

  a[href]
  {
    color: black;
    text-decoration:underline;
    // min-width: 44px;
    // min-height: 44px;
    // display: inline-flex;
    // align-items: center;
  }
}

.bold {
  font-weight: bold;
}

.citat{
  padding: 15px !important;
  font-weight: bold !important;
  width: 70% !important;
  font-size: em(25) !important;
  //line-height: calculateRem(29px) !important;
  line-height: 1.5 !important;
  letter-spacing: -0.74px !important;
  margin-block-start: 1rem !important;
  margin-block-end: 1rem !important;

  &:before,
  &:after {
    content: '"';
  }
}

.citatkilde{
  padding-left: 15px !important;
  width: 70% !important;
  font-size: em(13) !important;
  font-style: italic !important;
 // line-height: line-height(em(13), 1.5, calculateRem-number(16px))  !important;
 //line-height: 1.2307692307692308em !important;
 line-height: 1.5 !important;
  letter-spacing: -0.4px;
  color: #4f4f4f;
  position: relative !important;
  top: -20px !important;
}

.text-align-center{
  text-align: center;
}

//.news-first-title h1{
//  @include news-first-title;
//  word-break: break-all
//}


.fade-content p{
 @include standard;
 word-break: break-word;
}


.editor-content p{
  @include standard;
  word-break: break-word;
}

.factbox h3{
  @include headline-3-mellemoverskrift;
  word-break: break-word;
}

.factbox h3.name{
  @include standard;
  font-weight: normal;
  word-break: break-word
}

.factbox p{
  @include standard;
  word-break: break-word
}

.news-title p {
  @include content-manchet;
  word-break: break-word
}

.presentation{
  .news-title p{
    @include standard()
  }
} 

.title-block{
  .news-title p{
    @include standard()
  }
}

.title-block{
  .description-full-width p{
    @include content-manchet()
  }
}

.categoryPage{
  .news-title p {
    @include content-manchet;
  }
}

.campaignPage{
  .title-block h1{
    @include headline-1;
  }
}

.img-fluid{
  img{
    max-width: 100%;
    height: auto;
  }
}

.expert-area-title{
  font-size: $base-size;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: -0.5px;
  margin-block-start: 2rem;
  margin-block-end: 0rem;
}

h2.expert-area-title{
  font-size: $base-size;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: -0.5px;
  margin-block-start: 2rem;
  margin-block-end: 0rem;
}