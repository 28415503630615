.warning-banner {
  background-color: $secondary-color;
  padding: 15px;
  color: $carousel-text-color;
  width: 100%;
  transition: box-shadow 0.25s;

  .btn-close {
    .fa {
      color: $white;
    }
  }

  .icon {
    display: flex;
    justify-content: center;

    .fa {
      margin: 10px 0px;
    }
  }

  .link {
    color: $carousel-text-color;

    &.link-secondary {
      &:hover {
        color: $carousel-text-color;
      }
    }
  }
}
