.table-of-content {
  width: 100%;
  background-color: $indexModule;
  color: $white;
  padding: 80px 0;
  margin: 60px 0 60px 0;

  .list {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 98px;
    -moz-column-gap: 98px;
    column-gap: 98px;

    @include media-breakpoint-down(md) {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
    }

    & > * {
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    .element {
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;

      .item {
        color: $white;
        display: block;
        margin-bottom: 40px;

        .title {
          @include table-of-content;
          text-decoration: underline;
        }

        h2.title {
          @include table-of-content;
          text-decoration: underline;
          margin-bottom: 0px;
        }

        .description {
          @include media-breakpoint-down(md) {
            width: 100%;
          }
        }
      }
    }
  }
}
