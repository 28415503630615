@import '../utilities/rem-calculator';

.sorting-container {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  form {
    display: flex;

    input[type="submit"] {
      margin-left: 10px;
    }
  }
}

.sorting {
  display: flex;
  margin-top: 20px;

  @include media-breakpoint-up(md) {
    justify-content: flex-end;
    margin: 0;
  }

  .dropdown {
    .btn {
      min-width: 44px;
      min-height: 44px;
      display: flex;
      align-items: baseline;

      .text {
        margin-right: 5px;
      }

      &:focus {
        border: 1px solid gainsboro;
      }

      &:after {
        @extend %fa-icon;
        @extend .fas;
        content: fa-content($fa-var-chevron-down);
        color: $fki-dark-green;
        border: none;
        font-size: em(12);
        //font-size: 12px;
      }

      &:hover {
        &:after {
          color: $white;
        }
      }
    }
  }
}
