@import '../utilities/rem-calculator';

html:not([data-scroll="0"]) {
  header {
    position: sticky;
    z-index: 1000000;
    width: 100%;
    top: 0;
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
  }
}

html{
  scroll-padding-top: 150px;
} 

.header {
  box-sizing: border-box;
  transition: box-shadow 0.25s;

  .navbar-toggler {
    border: none;
    min-width: 44px;
    min-height: 44px;
    //display: flex;
    //align-items: center;

    .fa {
      //font-size: 25px;
      font-size: em(25);
      color: $black;
    }
  }

  .navbar {
    //height: 105px;
    background-color: $white;
    display: flex;
    flex-direction: column;
    padding: 0px 10px 0px 10px;

    @include media-breakpoint-down(lg) {
      height: auto;
    }

    .navbar-brand {
      padding-top: 18px;
      padding-bottom: 10px;
      //margin-top: 18px;
      //margin-bottom: 10px;

      @include media-breakpoint-down(lg) {
        margin-left: 1rem;
      }

      .logo {
        height: 80px;
        /*max-width: 100%;*/

        &--sticky {
          width: 40px;
          margin: 0;
          display: none;
        }

        @include media-breakpoint-down(lg) {
          &--no-sticky {
            display: none;
          }
          &--sticky {
            display: block; 
            height: auto;
          }
        }

        @include media-breakpoint-down(md) {
          &--no-sticky {
            display: block;
          }
          &--sticky {
            display: none;
          }
        }

        @media (max-width:450px){
          &--no-sticky {
            display: none;
          }
          &--sticky {
            display: block; 
          }
        }
      }
    }

    #navbarMobile, 
    #navbarDesktop {
      @include media-breakpoint-down(md) {
        height: 100%;
        width: 95%;
        position: fixed;
        z-index: 1000;
        top: 0;
        right: -100%;
        background-color: $white;
        overflow-x: hidden;
        transition: 0.5s;
        padding: 16px 30px;
        display: block;
        box-shadow: 0px -5px 10px -5px $black;
      }

      .icons-menu{
        color: $black;
        font-size: 2em;
      }

      .navbar-mobile-close {
        @include small-text;
        color: $small-text-green;
        min-width: 44px;
        min-height: 44px;
        display: flex;
        align-items: center;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }

      .navbar-mobile-search {
        min-width: 44px;
        min-height: 44px;
        display: flex;
        align-items: center;
      }

      .navbar-nav-mobile {
        display: none;
        margin: 30px 0 20px 0;

        @include media-breakpoint-down(md) {
          display: block;
        }
      }
    }

    .navbarMobileMenuClosed {
      right: -100% !important;
      visibility: hidden !important;
      display: none !important;

      .global-menu-mobile {
        .link{
          display: none !important;
          visibility: hidden !important;
        }
      }
    }

    .navbarMobileMenuOpened {
      right: 0px !important;
    }

    .btn-menu{
      .more{
        vertical-align: bottom;
        color: $black;
        font-weight: bold;
        margin-bottom: 2px;
      }
      
    }

    .navbar-mobile-brand { 
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      order: 1;
      width: 100%;

      @include media-breakpoint-up(lg) {
        display: none;
      }

      .logo {
        width: 40px;
        display: block !important;
      }
    }

    .navbar-collapse {
      justify-content: flex-end;
      align-items: flex-end;
      height: auto;
      flex-grow: initial;
      flex-direction: column;
      max-width: 70%;

      @include media-breakpoint-down(lg) {
        height: auto;
      }

      .btn-toggle-menu--mobile {
        display: none;
      }

      .btn-toggle-menu--desktop {
        &:focus,
        &.focus {
          box-shadow: none;
          outline: none;
        }
      
        &:focus:not(:focus-visible){
          box-shadow: none;
          outline: none;
        }
      
        &:focus-visible{
          box-shadow: none;
          outline: 2px solid black !important;
        }
      }


      @include media-breakpoint-down(md) {
        max-width: 100%;
        .btn-toggle-menu--desktop {
          display: none;
        }

        .btn-toggle-menu--mobile {
          display: inline-block;
        }
      }

      .navbar-nav {
        //height: 40px;
        height: 100%;

        @include media-breakpoint-down(md) {
          display: none;
        }

        .nav-item {
          &:last-child {
            .nav-link {
              margin-right: 0;
            }
          }

          .nav-link {
            color: $fki-black;
            margin-right: 20px;
            padding-left: 0;
            padding-right: 0;
            padding-top: 3px;
            //padding-bottom: 0;
            @include regular-text-spacing;
            font-weight: bold;
            cursor: pointer;
            white-space: nowrap;
            min-width: 44px;
            min-height: 44px;
            display: flex;
            align-items: flex-start;

            &[aria-expanded="true"] {
              //border-bottom: 2px solid $fki-red;
              text-decoration: underline 2px $fki-red;
              text-underline-offset: 10px;
            }

            @include media-breakpoint-down(lg) {
              margin-right: 15px;
              font-size: em(14);
              //font-size:14px;
            }

            @include media-breakpoint-down(md) {
              margin-right: 20px;
              font-size: em(15);
              //font-size:15px;
            }
          }

          .campaign {
            color: $fki-red;
          }
        }
      }

      .form-control{
        &:focus,
        &.focus {
          box-shadow: none;
          outline: none;
        }
      
        &:focus:not(:focus-visible){
          box-shadow: none;
          outline: none;
        }
      
        &:focus-visible{
          box-shadow: none;
          outline: 2px solid black !important;
        }
      }
    }
  }

  .global-menu {
    height: 100%;

    @include media-breakpoint-down(md) {
      height: auto;
    }

    .list {
      margin-bottom: 0;
      list-style-type: none;
      display: flex;
      justify-content: flex-end;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        padding: 0;
      }

      .link {
        margin: 0 0 0 25px;
        @include small-text;
        color: $small-text-green;
        text-decoration: none;

        @include media-breakpoint-down(md) {
          margin: 10px 0;
        }
      }
    }
  }

  .global-menu-mobile {
    height: 100%;

    @include media-breakpoint-down(md) {
      height: auto;
    }

    @include media-breakpoint-up(lg) {
      display: none;
      visibility: hidden;
    }

    .list {
      margin-bottom: 0;
      list-style-type: none;
      display: flex;
      justify-content: flex-end;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        padding: 0;
      }

      .link {
        margin: 0 0 0 25px;
        @include small-text;
        color: $small-text-green;
        text-decoration: none;
        min-width: 44px;
        min-height: 44px;
        display: flex;
        align-items: center;

        @include media-breakpoint-down(md) {
          margin: 0;
        }

        &:focus,
        &.focus {
          box-shadow: none;
          outline: none;
        }
      
        &:focus:not(:focus-visible){
          box-shadow: none;
          outline: none;
        }
      
        &:focus-visible{
          box-shadow: none;
          outline: 2px solid black !important;
        }
      }
    }
  }

  .global-menu-desktop {
    height: 100%;
    margin-bottom: 10px;

    @include media-breakpoint-down(md) {
      //height: auto;      
      display: none;
      visibility: hidden;
    }

    .list {
      margin-bottom: 0;
      list-style-type: none;
      display: flex;
      justify-content: flex-end;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        padding: 0;
      }

      .link {
        margin: 0px 0 0 25px;
        @include small-text;
        color: $small-text-green;
        text-decoration: none;
        min-width: 44px;
        min-height: 44px;
        display: flex;
        align-items: center;


        @include media-breakpoint-down(md) {
          margin: 10px 0;
        }

        &:focus,
        &.focus {
          box-shadow: none;
          outline: none;
        }
      
        &:focus:not(:focus-visible){
          box-shadow: none;
          outline: none;
        }
      
        &:focus-visible{
          box-shadow: none;
          outline: 2px solid black !important;
        }
      }
    }
  }

  .mega-menu {
    position: absolute;
    max-height: 550px;
    overflow-y: auto;
    width: 100%;
    z-index: 1;
    background: $white;
    border-top: 1px solid rgba($color: $seperator-grey, $alpha: 1);
    border-bottom: 1px solid rgba($color: $seperator-grey, $alpha: 1);
    box-shadow: $black 0px 5px 20px -20px;

    @include media-breakpoint-down(md) {
      display: none;
    }

    .content {
      width: 100%;
      margin-right: auto;
      margin-left: auto;
      padding: 0px 15px 15px 15px;

      @include media-breakpoint-up(sm) {
        width: 540px;
      }
      @include media-breakpoint-up(md) {
        width: 720px;
      }
      @include media-breakpoint-up(lg) {
        width: 960px;
      }
      @include media-breakpoint-up(xl) {
        width: 1140px;
      }

      .subject {
        margin-bottom: 25px;
      }

      .mega-menu-header1{
        @include headline-1;
        line-height: normal;
      }

      .title {
        font-weight: bold;
        @include regular-text-spacing;
      }

      .nav-list {
        list-style-type: none;
        padding: 0;
        margin-bottom: 1rem;

        .item {
          padding: 5px 0 5px 0;

          &:first-child {
            padding: 5px 0 5px 0;
          }

          .link {
            @include regular-text-spacing;
            text-decoration: none;
            min-width: 24px;
            min-height: 24px;
            display: inline-flex;
            align-items: flex-end;
          }
        }
      }
    }
  }

  .mega-menu-close{
    text-align: right;
    padding-top: 0px
  }

  a.mega-menu-close-button{
    min-height: 44px;
    min-width: 44px;
    display: inline-flex;
    align-items: center;

    &:hover{
      text-decoration: none;
    }
  }

  .scrollMega{
    //max-height: 80vh;
    //overflow: auto;
  }

  .accordion {
    .card {
      border: none;
      border-radius: 0;
      background-color: transparent;
      background-clip: initial;
      border-top: 1px solid $seperator-grey;
      border-bottom: 1px solid $seperator-grey;

      &:first-child {
        border: none;
      }

      .card-header {
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 5px 0;

        &:first-child {
          border-radius: 0;
        }

        .mega-menu-header2{
          @include headline-2;
          margin-bottom: 0;
        }

        h2{
          margin-bottom: 0;
        }

        .btn-link {
          color: $black;
          padding: 0px;
          text-decoration: none;
          font-weight: bold;
          text-align: left;
          min-width: 44px;
          min-height: 44px;
          //display: flex;
          align-items: center;
          display: flex;
          padding: 15px 0;
          //align-items: baseline;

          .icons-add {
            color: $fki-red;
            font-size: 1.6em;
            vertical-align: middle;
            :after{
              color: $fki-red;
            }
          }
          .icons-add-forsvarsministeriet:after {
            color: $fki-red !important;
          }
    
          .icons-add-forsvaret:after {
            color: $fki-red !important;
          }
    
          .icons-add-hjemmeværnet:after {
            color: $fki-red !important;
          }
    
          .icons-add-forsvarets-efterretningstjeneste:after {
            color: $fki-red !important;
          }
    
          .icons-add-beredskabsstyrelsen:after {
            color: $fki-orange !important;
          }
    
          .icons-add-forsvarsministeriets-auditørkorps:after {
            color: $fki-red !important;
          }
    
          .icons-add-hæren:after {
            color: $fki-red !important;
          }
    
          .icons-add-søværnet:after {
            color: $fki-red !important;
          }
    
          .icons-add-søværnet-og-beredskabsstyrelsen:after {
            color: $fki-red !important;
          }
    
          .icons-add-flyvevåbnet:after {
            color: $fki-red !important;
          }
        }
      }

      .card-body {
        padding: 0px 30px 10px 30px;

        .title {
          font-weight: bold;
          @include regular-text-spacing;
        }

        .nav-list {
          list-style-type: none;
          padding: 0;

          .item {
            padding: 0;
            //line-height: calculateRem(23px);
            line-height: 1.5;

            &:first-child {
              padding: 0;
            }

            .link {
              @include regular-text-spacing;
              //line-height: calculateRem(18px) !important;
              line-height: 1.5 !important;
              text-decoration: none;
            }
          }
        }
      }
    }

    form{
      width:100%;
    }
  }

  .CampaignPage {
    &.navbar {
      padding: 20px 17px;
    }

    .card.card-body {
      padding: 0 !important;
      flex-direction: row;
    }

    + #collapseMenuDesktop > .card.card-body > .mega-menu {
      top: 140px;
      left: 0px;
    }
  }

  .card-menu {
     padding-left: 0 !important;
     padding-right: 0 !important;
  }

  .discreet-menu {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

}

.icons-keyboard-arrow-up{
  color: $white !important;
  opacity: 1 !important;
  font-size: 2em;
}

.icons-report-problem{
  color: $white;
  font-size: 2em;
}

.icons-close{
  color: $black;
}

.icons-menu{
  color: black;
  font-size: 1.8em;
}

 a#skip, a#skip:hover, a#skip:visited{
  padding: 8px;
  position: absolute;
  background: #ffffff;
  left: 50%;
  height: 44px;
  transform: translateY(-100%);
  transition: transform 0.3;
  margin: auto;
  color: #000000;
  text-decoration: none;
}

a#skip:active, a#skip:focus{
  transform: translateY(0%);
  z-index: 50; 
  border: solid 1px solid;
}
/* .header_skip{
  padding: 8px;
  position: absolute;
  background: #e77e23;
  left: 50%;
  height: 30px;
  transform: translateY(-100%);
  transition: transform 0.3;
}

 a#skip:forcus{
  transform: translateY(0%);
  z-index: 20;
  position: static;
} */