.factbox {
  @include media-breakpoint-down(md) {
    margin: 15px 0 0 0;
  }
  
  .contact {
    .name {
      @include navigation-description;
      //@include fontSize(20px);
      font-size: em(20);
    }

    .link.link-secondary {
      min-width: 44px;
      min-height: 44px;
      display: flex;
      align-items: flex-start;
    }

    ul.event{
      margin-bottom: 0;
    }

    .description > p {
      @include regular-text-spacing;
    }

    .event {
      h2.subtitle {
        @include regular-text-spacing;
        margin-bottom: 0px;
      }

      h3.subtitle {
        @include regular-text-spacing;
        margin-bottom: 0px;
      }

      .title {
        @include regular-text-spacing;
      }

      .description > p {
        @include regular-text-spacing;
      }

      .material-icons-outlined{
        vertical-align: text-bottom;
        font-size: 24px;
      }
    }
  }

  .event {
    list-style-type: none;
    padding: 0;

    li {
      margin-bottom: 15px;
      
      h2.subtitle {
        @include regular-text-spacing;
        margin-bottom: 0px;
      }

      h3.subtitle {
        @include regular-text-spacing;
        margin-bottom: 0px;
      }

      .title {
        @include regular-text;
        font-weight: bold;
        padding: 0;
      }

      .description {
        @include regular-text;
        margin: 5px 0 3px 0;
      }

      .description-link {
        @include regular-text;
        margin: 0;
      }

      .material-icons-outlined{
        vertical-align: text-bottom;
        font-size: 24px;
      }

      &.contact-link{
        margin-bottom: 0;
      }
    }
  }
}

.btn-download{
  text-decoration: none !important;
}

.icons-description{
  font-size: 2.4em;
  margin-right: 0.5rem !important;
}

.icons-create{
  font-size: 24px;
  
}

.icons-today{
  font-size: 24px;
}