@import '../utilities/rem-calculator';

.hero {
  position: relative;
  overflow: hidden;

  @include media-breakpoint-down(lg) {
    overflow: visible;
  }

  .image {
    position: inherit;

    @include media-breakpoint-down(lg) {
      height: auto;
    }

    img {
      position: relative;
      width: 100%;

      @include media-breakpoint-down(lg) {
        transform: none;
        top: auto;
      }
    }
  }

  .text-block {
   

    .container {
      .row {
        position: relative;

      }
    }
  }

  .align {
    width: 100%;

    &--left-top {
      .info-box {
        left: 0;
        top: 0;

        @include media-breakpoint-up(xl) {
          top: -500px;
        }
      }
    }

    &--right-top {
      .info-box {
        right: 0;
        top: 0;
        background-color: $primary-color;

        @include media-breakpoint-up(xl) {
          top: -500px;
        }
      }
    }

    &--left-bottom {
      .info-box {
        left: 0;
        bottom: 0;
        background-color: $primary-color;
      }
    }

    &--right-bottom {
      .info-box {
        right: 0;
        bottom: 0;
        background-color: $primary-color;
      }
    }
    
    &--middel {
      .info-box {
        @include media-breakpoint-up(xl) {
          left: calc(50% - 242px);
        }
        bottom: 0;
        background-color: $primary-color;
      }
    }
  }

  .info-box {
    position: relative;
    margin: 0;
    padding: 20px 0;
    width: 100%;
    background-color: $primary-color;
    color: $white;

    @include media-breakpoint-up(xl) {
      position: absolute;
      width: 484px;
      margin: 40px 0;
      padding: 40px;
    }

    .info-title {
      @include banner-headline;
      word-break: break-word;

      @include media-breakpoint-down(md){
        //font-size: 18px;
        font-size: em(18);
        //line-height: calculateRem(24px);
        line-height: 1.5;
      }
    }

    .info-text {
      color: $white;
      margin-top: 25px;
      //margin-bottom: 15px;

      p {
        word-break: break-word;
      }
    }

    .info-link {
      color: $white;
      text-decoration: underline;
      min-width: 44px;
      min-height: 44px;
      display: flex;
      align-items: flex-end;
    }
  }
}
