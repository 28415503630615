@function calculateRem($size) {
  $remSize: $size / 17px;
  @return #{$remSize}rem;
}



@mixin fontSize($size) {
  font-size: $size; //Fallback in px
}

$browser-context: 17; // Default

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em
}

@function line-height($font-size, $ratio-to-base-line-height, $base-line-height: $line-height, $relative-base-font-size: 1) {
  $abs-base-font-size: $relative-base-font-size * 16;
  $line-height: $base-line-height * $ratio-to-base-line-height;
  @return ($relative-base-font-size * $abs-base-font-size * $line-height) / ($font-size * $abs-base-font-size);
}