@import '../utilities/rem-calculator';

.content {
  .news-list {
    .list {
      list-style-type: none;
      padding-left: 0px;

      .item {
        display: flex;
        flex-direction: column;
        padding: 30px 0px;
        border-bottom: 1px solid $seperator-grey;

        &:first-child {
          padding: 10px 0 30px 0;
        }

        a {
          color: $black;
          text-decoration: none;

          &:hover {
            text-decoration: none;
          }

          .image {
            @include media-breakpoint-down(sm) {
              width: 100%;
            }
          }

          .title {
            @include news-list-headline;
            margin: 0px 0 8px 0;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;

            @include media-breakpoint-down(md) {
              margin: 10px 0 5px 0;
            }
          }

          h2.title {
            @include news-list-headline;
            margin: 0px 0 8px 0;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;

            @include media-breakpoint-down(md) {
              margin: 10px 0 5px 0;
            }
          }

          .info {
            //@include small-text;
            //margin: 10px 0 15px 0;
            margin-bottom: 10px;

            @include media-breakpoint-down(md) {
              margin-bottom: 5px;
            }

            .date,
            .category {
              //@extend .info;
              color: $black;
              font-size: $base-size;
            }

            .date {
              @include media-breakpoint-down(md) {
                margin: 5px 0;
              }
            }

            .category.seperator {
              color: $black;
            }
          }

          .description {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }
    }
  }
}

.list-navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
  
}

/* Search result header */

.content {
  .amount{
    #authority
    {
      &.highlight{
        color:rgb(200, 38, 19);
      }
  
      .interval{
        color:rgb(200, 38, 19);
        //font-size:14px;
        font-size: em(14);
      }
    }

    p{
      font-size: em(14);
      //font-size: $base-size;
      //font-size:14px;
    }
  }
}

/* Search results external links */

.content {
  .news-list {
    .externalLink{
      width: 100%;
    }
    .externalLinkLeft{
      width:5%;
      float:left;
    }
    .externalLinkRight{
      margin-left: 7%;
    }
  }
}