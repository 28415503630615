@import '../utilities/fonts.scss';

.twitter-text{
    text-align: left;
    font-size: em(46);
    //font-size: 32px;
    font-weight: bold;
    letter-spacing: -1px;
    //line-height: 3.17647rem;
    line-height: 1.5;
    letter-spacing: -2px;

}

.fade-content{
    .twitter-text{
    text-align: left;
    margin-bottom: 40px;
    @include headline-1;
    }
}

.twitter-feed{
    overflow: hidden;
    
    a.twitter-timeline{
        color: black;
        text-decoration: underline;
    }
}