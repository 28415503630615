@import "../utilities/rem-calculator";

.image-text {
  @include small-text;
  color: $small-text-grey;
  margin-bottom: 30px;

  &--no-space {
    margin-bottom: 0px;
  }
}

.dobble-image-block {
  .image-text {
    margin-bottom: 0px;
  }
}

.editor {
  .row {
    margin: 0;

    .col-lg-6 {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    .dobble-image-block {
      .image-text {
        margin-bottom: 0px;
      }
    }
  }
}


//editor
.image-text {
  /*@include small-text;*/
  font-size: em(13) !important;
  //line-height: calculateRem(16px) !important;
  line-height: 1.5 !important;
  letter-spacing: -0.4px;
  color: $small-text-grey;
  margin-bottom: 30px !important;

  &--no-space {
    margin-bottom: 0px;
  }
}



pre{
  img{
    width: 100% !important;
    height: auto;
  }
}