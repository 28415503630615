.page-header {
  @include media-breakpoint-down(md) {
    h1 {
      word-break: break-word;
    }
  }

  .title {
    &--special {
      color: $secondary-color;
    }
  }

  .news-title {
    h2 {
      @include content-manchet;
    }
  }

  .news-date {
    @include headline-3-manchet;
    color: $small-text-grey;
  }
}
