.email-sign-up {
  width: 100%;
  margin-left: 5px;

  .btn {
    .icon{
      vertical-align: top;
    }

    .mr-3{
      vertical-align: middle;
    }
  }

  .alert-banner {
    margin: 20px 0px 20px 0px;
  }

  .error-banner {
    padding: 15px;
    border: 2px solid red;
    width: 100%;
    margin: 20px 0px 20px 0px;
  }

  .form-group{
    margin-top: 1rem;
    
    input.form-control{
      max-width: 600px;

      &:focus{
        box-shadow: none;
        outline: 2px solid black !important;
      }
    
      &:focus:not(:focus-visible){
        box-shadow: none;
        outline: none;
      }
    
      &:focus-visible{
        box-shadow: none;
        outline: 2px solid black !important;
      }
    }
  }
}

.emailListTitle {
  //font-size: 15px;
  font-size: em(15);
  font-weight: bold;
  }

legend.emailListTitle {
  font-size: em(15) !important;
  font-weight: bold !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  line-height: 1.5;
  letter-spacing: -0.5px;
  line-break: loose;
  }

.emailListHeadTitle{
  //font-size: 17px;  
  font-size: em(17);
  font-weight: bold;
}
  
.listItem {
  //font-size: 15px;
  font-size: em(15);
}

.email-sign-up-header {
  margin-left: -15px;
  //font-size: 38px;
  font-size: em(38);
}

.errorTextList {
  //color: red;
  //font-size: 13px;
  font-size: em(13);
}

.errorTextEmail {
  //color: red;
  //font-size: 13px;
  font-size: em(13);
}

.listMainItemCheck{
  padding-left: 2px !important;
  margin-left: 2px;
}