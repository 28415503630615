.related-content {
  .title {
    @include related-content-title;
    margin: 0 0 23px 0;
  }
  .item {
    margin-bottom: 15px;
    max-height: 1000px;

    a {
      color: $black;

      &:hover {
        text-decoration: none;
      }

      .title {
        @include navigation-headline;
        margin: 15px 0 8px 0;
      }

      .title-promoted-content {
        @include navigation-headline-promoted;
        margin: 5px 0 8px 0;
      }

      h2.title {
        @include navigation-headline;
        margin: 15px 0 8px 0;
      }

      h2.title-promoted-content {
        @include navigation-headline-promoted;
        margin: 5px 0 8px 0;
      }

      .description {
        //@include headline-3-manchet;
        word-break: break-word;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        @include fontSize($base-size);
        //line-height: calculateRem(27px);
        line-height: 1.5;
        letter-spacing: -0.5px;
        line-break: loose;
      }

      .date,
      .category {
        font-size: $base-size;
      }
    }
  }

  &--special {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 45px 0;

      @include media-breakpoint-down(md) {
        margin: 0 0 15px 0;
      }

      .title {
        @include headline-2;
      }

      h2.title {
        @include headline-2;
      }

      .link {
        @include media-breakpoint-down(md) {
          margin: 0 0 15px 0;
        }
      }

      @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: baseline;
      }
    }
  }


  .special {
    margin: 0 0 10px 0;
    padding: 0 0 30px 0;

    .item {
      .title {
        margin-top: 0px;
      }

      .description {
        //font-weight: bold;

        + .special-content {
          .description {
            font-weight: normal;
            padding-top: 5px;
          }
        }
      }
    }
  }

  .feed {
    column-count: 2;
    column-gap: 98px;

    @include media-breakpoint-down(md) {
      column-count: 1;
    }

    &:after {
      content: "";
      clear: both;
    }

    .item {
      display: inline-block;
      border-bottom: 1px solid $seperator-grey;
      margin: 15px 0;
      padding: 0 0 15px 0;
      width: 100%;

      @include media-breakpoint-down(lg) {
        margin: 20px 0;

        &:first-child {
          margin: 0 0 15px 0;
        }
      }
    }
  }
}

.overlay-container{
  max-height: 160px;
  @include media-breakpoint-down(md){
    .header{
      height: auto;
    }
    .description{
      height: auto !important;
    }
  }
  .header
  {
    height: 60px;
  }

  .description
  {
    height: 35px;
  }
}

.bg--grey { 
    @include media-breakpoint-down(md){
      padding: 10px;
    }
    @include media-breakpoint-down(sm){
      padding: 10px;
    }
}
