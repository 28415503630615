// Farver definitioner

// FMN
$fki-dark-green: #3f5b58;

.dark-green {
    color: $fki-dark-green;
}

// FSV
$fki-red: #c8102e;

.red {
    color: $fki-red;
}

// HJV
$fki-dark-red: #8d1b3d;

.dark-red {
    color: $fki-dark-red;
}

// FE
$fki-black: #000000;

// BRS
$fki-orange: #f5821e;

.orange {
    color: $fki-orange;
}

// FAUK
$fki-purple: #93358c;

.purple {
    color: $fki-purple;
}

// Hæren
$fki-green: #597e50;

.green {
    color: $fki-green;
}

// Søværnet
$fki-blue: #002855;

.blue {
    color: $fki-blue;
}

// Flyvevåbnet
$fki-lightblue: #489ed8;

.lightblue {
    color: $fki-lightblue;
}

// Border colors
$seperator-green: #3E5D58;
$seperator-grey: #767676;

$border: #333939;
$border-2: #CDD2D2;

// Footer
$footer: #F3F3F3;

// Special font colors
$small-text-dark-grey: #636363;
$small-text-green: #465A5A;
$small-text-grey: #4f4f4f;


// Standard colors
$black: #000000;
$white: #ffffff;
$gray: #e5e5e5;