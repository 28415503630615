@import '../utilities/rem-calculator';

.rss{
    .title{
        @include headline-3;
    }
}

.rssDescription{
    @include standard;
    word-break: break-word;
    white-space: pre-line;
}

.rssDescriptiveHead{
margin-bottom: 40px;
}

.rsslist{
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.rssAutority{
    border-top: 1px solid $secondary-color;
}

.rsslist{
    li:last-child{
        hr{
            display: none;
        }
    }
}

.date{
    font-size: em(15);
    //font-size: 15px;
    //line-height: calculateRem(22px);
    line-height: 1.5;
    letter-spacing: -0.4px;
    color: #4f4f4f;
}

.listResult{
    h3{
        @include headline-3;
        a{
            text-decoration: none;
            color: $black;
            &:hover {
                color: $link;
              }
        }
    }
}