@import '../utilities/rem-calculator';

.breadcrumb {
  background-color: $white;
  margin: 0px 0 0px 0;
  padding: 0;
  @include regular-text-bold;

  .breadcrumb-item {

    //display: flex;
    //align-items: center;

    &:only-child {

      &:after {
        display: inline-block;
        padding-right: 0.5rem;
        content: "/";
        color: $fki-dark-red;
        padding-right: 2px;
      }
    }

    &:before {
      color: $fki-dark-red;
      padding-right: 2px;
    }

    .link {
      color: $small-text-grey;
      //font-size: 13px;
      font-size: em(13);
      min-width: 44px;
      min-height: 44px;
      display: inline-flex;
      align-items: center;

      &:hover,
      &:focus,
      &:active {
        color: $small-text-dark-grey;
      }
    }
  }
}

.removeSpace
{
  margin: 0px !important;
}

.campaign-breadcrumb .container{
  padding-left: 0px;
}