@import '../utilities/rem-calculator';

.related-content {
  .image-container {
    position: relative;
    width: 100%;

    &.no-img {
      height: 317px;
      max-height: auto;
      background-color: $border;
    }

    @include media-breakpoint-down(md) {
      height: auto;
      max-height: none;
    }

    .image {
      width: 100%;
    }

    .overlay-container {
      position: absolute;
      right: 20px;
      bottom: 22px;
      left: 20px;
      background-color: $white;
      padding: 16px;

      &--bg {
        background-color: $white;

        .link {
          text-decoration: none;
        }
        .header{
          align-items: baseline;
        }
        .title {
          //padding-bottom: 5px;
          border-bottom: 2px solid $fki-red;
          color: $black;
          width: 100%;
        }
        .icon {
          display: none;
        }

      }

      @include media-breakpoint-down(md) {
        padding: 20px 20px 20px 20px;
      }

      &.Authority {
        .link {
          text-decoration: none;

          .header {
            .title {
              margin: 0 10px 0 0;
              color: $white;
            }

            .icon {
              color: $white;
            }
          }
        }

        &--forsvarsministeriet {
          background-color: $fki-dark-green;
        }

        &--forsvaret {
          background-color: $fki-red;
        }

        &--hjemmeværnet {
          background-color: $fki-dark-red;
        }

        &--forsvarets-efterretningstjeneste {
          background-color: $fki-black;
        }

        &--beredskabsstyrelsen {
          background-color: $fki-blue;
        }

        &--forsvarsministeriets-auditørkorps {
          background-color: $fki-purple;
        }

        &--hæren {
          background-color: $fki-green;
        }

        &--søværnet{
          background-color: $fki-blue;
        }

        &--flyvevåbnet {
          background-color: $fki-lightblue;
        }
      }

      .link {
        .header {
          display: flex;
          justify-content: space-between;

          .title {
            @include news-list-headline;
            margin-right: 10px;
            margin-top: 0px;

            @include media-breakpoint-down(lg) {
              @include navigation-headline;

              &--small {
                font-size: em(14);
                //@include fontSize(14px);
              }
            }
          }

          .icon {
            font-size: em(32);
            //font-size: 32px;
            color: $link;
            font-weight: bold;

            @include media-breakpoint-down(lg) {
              &--small {
                //@include fontSize(18px);
                font-size: em(18);
              }
            }
          }
        }

        .description {
          @include regular-text-spacing;
          //line-height: calculateRem(18px);
          line-height: 1.5;
          font-size: em(14) !important;
          //font-size: 14px !important;
          //display: -webkit-box;
          //-webkit-line-clamp: 2;
          //-webkit-box-orient: vertical;
          //overflow: auto;
          margin-top: 5px !important;

          @include media-breakpoint-down(lg) {
            &--small {
              //@include fontSize(14px);
              font-size: em(14);
            }
          }
        }
        .description::-webkit-scrollbar{
          display: none;
        }
        .description{
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
      }
    }

    .overlay-container--bg {
        .link {
          .description {
            margin-top: 10px !important;
          }
        }
      }

  }
}
