@import '../utilities/rem-calculator';

.link {
  &.link-primary {
    text-decoration: none !important;
    border-bottom: 2px solid $link;
    @include regular-text-bold;
    color: $black !important;
    min-width: 44px;
    min-height: 44px;
    display: inline-flex;
    align-items: flex-end;
    //align-items: flex-start;
  }

  &.link-secondary {
    text-decoration: underline;
    color: $black;
    min-width: 44px;
    min-height: 44px;
    display: inline-flex;
    align-items: center;
  }

  &.link-internal {
    text-decoration: none;
    color: $fki-dark-red;

    &:before {
      @extend %fa-icon;
      @extend .fas;
      content: fa-content($fa-var-external-link-alt);
      margin: 0 10px 0 0;
    }
  }

  &.link-primary,
  &.link-secondary,
  &.link-internal,
  &.link-some {
    &:hover {
      color: var(--linkcolor, $link);
    }

    &:focus {
      outline: 2px solid black;
      margin: 3px;
    }
  }

  .link-list {
    list-style-type: none;
    padding: 0;
    margin: 30px 0;

    .item {
      margin: 15px 0;
    }
  }
}


.using-mouse :focus{
  outline: none;
}

.container{
  p
  {
    a[href]:first-child
    {
      color: red;
      text-decoration: underline;
      // min-width: 44px;
      // min-height: 44px;
      // display: inline-flex;
      // align-items: center;
    }

    a[href]
    {
      color: black;
      text-decoration: underline;
      // min-width: 44px;
      // min-height: 44px;
      // display: inline-flex;
      // align-items: center;
    }
  }

  td
  {
    a[href]
    {
      color: black;
      text-decoration: underline;
      // min-width: 44px;
      // min-height: 44px;
      // display: inline-flex;
      // align-items: center;
    }
  }
}

.icons-external-link svg{
  position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.icons-link-launch{
  position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.icons-link-description{
  position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.icons-link-image{
  position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.icons-link-email{
  position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.authority{
  .icons-link-launch{
    font-size: 18px;
    //font-size: em(18);
    vertical-align: middle;
    position: static;
    top: auto;
    left: auto;
    -webkit-transform: none;
    transform: none;
    margin-bottom: 2px;
  }

  .icons-link-launch-external{
    vertical-align: middle;
    position: static;
    top: auto;
    left: auto;
    -webkit-transform: none;
    transform: none;
    margin-bottom: 2px;
  }
}
