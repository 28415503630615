@import '../utilities/rem-calculator';

.hero {
  .video {
    @include media-breakpoint-up(xl) {
      height: 800px;
    }
  }

  &--video {
    .text-block {
      @include media-breakpoint-up(lg) {
        //position: absolute;
        width: 100%;
        top: calc(800px / 2.7);

        &--bottom {
          top: auto;
        }
      }

      .middel {
        .info-middel {
          width: 100%;
          background: none !important;

          .middel-text {
            @include media-breakpoint-up(xl) {
              font-size: em(64);
              //@include fontSize(64px);
              //line-height: calculateRem(76px) !important;
              line-height: 1.5;

              p {
                font-size: em(20);
                //@include fontSize(20px);
              }
              
            }
          }
        }

        .strokeme {
          @include media-breakpoint-up(xl) {
            color: $white;
            text-shadow: 0px 0px 6px #000000;
          }
        }

        .info-box {
          @include media-breakpoint-up(lg) {
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }
}
