@import '../utilities/rem-calculator';

.btn,
.btn-group {
  &-primary:not(:disabled):not(.disabled):active {
    background-color: $border;
    border-color: $border;
  }

  &:focus,
  &.focus {
    box-shadow: none;
    outline: none;
  }

  &:focus:not(:focus-visible){
    box-shadow: none;
    outline: none;
  }

  &:focus-visible{
    box-shadow: none;
    outline: 2px solid black !important;
    margin: 2px;
  }

  &.btn-primary {
    box-sizing: border-box;
    padding: 17px 20px;
    border: 2px solid $border;
    border-radius: 0;
    background-color: $white;
    color: $black;
    @include button-text;

    &:active,
    &:hover,
    &.active,
    &.btn-primary:not(:disabled):not(.disabled).active {
      background-color: $border;
      border-color: $border;
    }

    &:hover {
      background-color: $border;
      color: $white;

      span,
      p {
        color: $white;
      }
    }

    &--small {
      padding: 10px 25px;
    }

    &--small-extra {
      padding: 6px 25px;
    }

    &:hover {
      .fa,
      .far,
      .fab {
        color: $white;
      }
    }
  }

  &.btn-overlay {
    z-index: 1;
    position: absolute;
    left: 50%;
    top: 0;
    margin-left: -105px;
    margin-top: 30px;

    &:before {
      @extend %fa-icon;
      @extend .fas;
      content: fa-content($fa-var-plus);
      margin-right: 10px;
    }
  }

  &.btn-download {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 992px) and (max-width: 1199px) {
      .text {
        //font-size: 14px;
        font-size: em(14);
      }
    }
  }
}

.show > .btn-primary.dropdown-toggle {
  background-color: $border;
  border-color: $border;
}

.btn-link {

  &:focus{
    outline: 2px solid black;
  }
}

.btn-cookie{
  color: $white;
  background-color: $white;
  border-color:$border;
  padding: 12px 13px;
  border-radius: 0;
  color: $black;
  @include button-text;
}

.btn-cookie:hover,
.btn-cookie:focus,
.btn-cookie:active,
.btn-cookie.active,
.open .dropdown-toggle.btn-cookie{
  color: $black;
  //background-color: #dae0e5;
  //border-color: #dae0e5;
  background-color: #CCC;
  border-color: #CCC;
}


.btn-cookie-all{
  color: $white;
  background-color: $white;
  border-color:$border;
  padding: 12px 13px;
  border-radius: 0;
  color: $black;
  @include button-text;
}

.btn-cookie-all:hover,
.btn-cookie-all:focus,
.btn-cookie-all:active,
.btn-cookie-all.active,
.open .dropdown-toggle.btn-cookie-all{
  color: $black;
  //background-color: #dae0e5;
  //border-color: #dae0e5;
  background-color: #CCC;
  border-color: #CCC;
}

.btn-mobil-cookie{
  @include media-breakpoint-down(sm){
    width: 100%;
    padding: 0;
  }
  @include media-breakpoint-up(md){
    padding: 0;
  }
}

.event{
  .title{
    .icons-place{
      vertical-align: text-bottom;
      //font-size: 24px;
      font-size: em(24);
    }
    
    .icons-event{
      vertical-align: text-bottom;
      //font-size: 24px;
      font-size: em(24);
    }
  }
}

.icon{
  svg{
    vertical-align: unset !important;
  }
}

.iconLinkText{
  margin-left: 16px;
}

.icons-link{
  position: relative;
    float: left;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.itemFeed{
  cursor: pointer;
}

.iconLink{
  cursor: pointer;
}