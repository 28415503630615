.page-menu {
  display: flex;
  padding-left: 15px;

  .call-to-action {
    width: 50%;
  }

  .accordion{
    padding: 5px 0 15px 0;
    border-bottom: 0.5px solid $border-2;
  }

  .card{
    background-color: transparent;
    border: 0;
  }

  .card-header{
    background-color: transparent;
    border: 0;
    padding: 0;
  }

  .card-header-default {
    background-color: transparent;
    border: 0;
    padding: 0;
    border-bottom: 2px solid $fki-red !important;

    .link{
      // border-bottom: 2px solid $fki-red;
    }
  }

  .card-body{
    background-color: transparent;
    border: 0;
    padding: 10px 0px 10px 0px;
  }

  .campaignhome{
    display: inline-block;
    font-size: em(17);
    //line-height: calculateRem(22px);
    line-height: 1.5;
    letter-spacing: normal;
    font-weight: bold;
    margin: 15px 0 0 0;
  }

  .campaignhometxt{
    width: max-content;
    border-bottom: 2px solid var(--bordercolor, $fki-red);
  }

  .campaignhomelink{
    display: inline-block;
    text-decoration: none;
  }

  .campaignmenuDefault{
    
    .btn-toggle{
      border-bottom: 2px solid var(--bordercolor, $fki-red);

    }
  }

  .btn-default .btn-link{
      border-bottom: 2px solid var(--bordercolor, $fki-red);
  }

  .btn-link{
    font-size: em(17);
    //line-height: calculateRem(22px);
    line-height: 1.5;
    letter-spacing: normal;
    font-weight: bold;
    margin: 15px 0 0 0;
  }

  .btn-toggle{
    color: $fki-black;
    margin-right: 20px;
    padding-left: 0;
    padding-right: 0;
    font-weight: bold;
    cursor: pointer;
    white-space: nowrap;
    border: 0;
    background-color: transparent;
    text-decoration: none;

    min-width: 44px;
    min-height: 44px;
    display: flex;
    align-items: flex-start;

    &[aria-expanded="true"] {
      border-bottom: 2px solid var(--bordercolor, $fki-red);
      //text-decoration: underline 2px var(--bordercolor, $fki-red);
      //text-underline-offset: 10px;
    }
  }

  .btn-defaultORG{
    border-bottom: 2px solid $fki-red;
  }

  .campaignmenu-item{
    white-space: normal;
    text-decoration: none;
    font-size: 1em;
    line-height: calculateRem(27px);
    letter-spacing: -0.4px;
    //padding: 5px 0px 5px 0px;
    min-width: 44px;
    min-height: 44px;
    display: flex;
    align-items: flex-start;
  }

  .campaignmenu{
    width: 200px;
    max-width: 200px;

    .btn-toggle{
      white-space: normal !important;
      text-align: left;
    }
  }

  .scroll-to-menu, .call-to-action {
    flex-flow: wrap;
    padding: 10px 0 10px 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-bottom: 0.5px solid $border-2;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      margin: 0;
      padding: 20px 0;
    }

    .item {
      margin-right: 25px;
      word-break: break-word;
      margin-bottom: 0;

      @include regular-text-bold;

      @include media-breakpoint-down(md) {
        margin: 5px 0;
      }

      &.promoted {
        margin-left: auto;
        margin-right: 0;

        @include media-breakpoint-down(md) {
          margin-left: 0;
          margin-right: 0;
        }
      }

      .link {
        color: $black;    
        min-width: 44px;
        min-height: 44px;
        display: flex;
        align-items: center;

        &:hover {
          color: $fki-red;
          text-decoration: none;
        }

        &--call-to-action {
          color: $black;
          min-width: 44px;
          min-height: 44px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
