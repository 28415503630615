#mosaicContainer {
  .btn-group {
    flex-wrap: wrap;

    @include media-breakpoint-down(md) {
      padding: 25px;
    }
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 30px 0;

    .item {
      &.display {
        &--forsvarsministeriet {
          background-color: $fki-dark-green;
        }

        &--forsvaret {
          background-color: $fki-red;
        }

        &--hjemmeværnet {
          background-color: $fki-dark-red;
        }

        &--forsvarets-efterretningstjeneste {
          background-color: $fki-black;
        }

        &--beredskabsstyrelsen {
          background-color: $fki-blue;
        }

        &--forsvarsministeriets-auditørkorps {
          background-color: $fki-purple;
        }

        &--hæren {
          background-color: $fki-green;
        }

        &--søværnet {
          background-color: $fki-blue;
        }

        &--flyvevåbnet {
          background-color: $fki-lightblue;
        }
      }

      &:hover {
        .item-container {
          .title {
            bottom: 0;
          }
          .image {
            img {
              opacity: 0.3;
              transform: scale(1.2, 1.2);
            }
          }
        }
      }

      .item-container {
        position: relative;
        overflow: hidden;

        .title {
          position: absolute;
          bottom: -200px;
          padding: 15px;
          width: 100%;
          color: $white;
          transition: all 0.5s ease;
          word-break: break-word;

          &.display {
            &--forsvarsministeriet {
              background-color: $fki-dark-green;
            }

            &--forsvaret {
              background-color: $fki-red;
            }

            &--hjemmeværnet {
              background-color: $fki-dark-red;
            }

            &--forsvarets-efterretningstjeneste {
              background-color: $fki-black;
            }

            &--beredskabsstyrelsen {
              background-color: $fki-blue;
            }

            &--forsvarsministeriets-auditørkorps {
              background-color: $fki-purple;
            }

            &--hæren {
              background-color: $fki-green;
            }

            &--søværnet {
              background-color: $fki-blue;
            }

            &--flyvevåbnet {
              background-color: $fki-lightblue;
            }
          }
        }

        .image {
          img {
            opacity: 1;
            transition: all 0.5s ease;
          }
        }
      }
    }

    .filter-btn-clear {
      margin: 40px auto;
    }
  }
}

.icons-arrow-forward{
  color: $white;
  font-size: 2em;
}

.icons-arrow-back{
  color: $white;
  font-size: 2em;
}


$offset: 187;
$duration: 1.4s;

.spinner {
  animation: rotator $duration linear infinite;
}

@keyframes rotator {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation:
    dash $duration ease-in-out infinite, 
    colors ($duration*4) ease-in-out infinite;
}

@keyframes colors {
	0% { stroke: #4285F4; }
	25% { stroke: #DE3E35; }
	50% { stroke: #F7C223; }
	75% { stroke: #1B9A59; }
  100% { stroke: #4285F4; }
}

@keyframes dash {
 0% { stroke-dashoffset: $offset; }
 50% {
   stroke-dashoffset: $offset/4;
   transform:rotate(135deg);
 }
 100% {
   stroke-dashoffset: $offset;
   transform:rotate(450deg);
 }
}