@import '../utilities/rem-calculator';

.editor-content{
  .factbox {
    .title{
      @include headline-3-mellemoverskrift;
    }

    .h3-subheader{
      @include headline-3-lille-overskrift;
      word-break: break-word;
    }
  }
}

.factbox {
  width: auto;
  height: auto;
  margin-bottom: 20px;
  padding: 45px;
  //background-color: rgba($color: $fki-dark-green, $alpha: 0.1);
  background-color: #F3F3F3;
  margin-bottom: 20px;

  .title {
    @include headline-3;
    padding-bottom: 15px;
  }
  
  .btnfocus:focus {
    //outline: 1px solid rgba($color: $fki-dark-green, $alpha: 0.1); 
    outline: 2px solid black;
  }



  .accordion {

    .card {
      background-color: transparent;
      padding: 0.75rem 0;
      border-left: 0;
      border-right: 0;
      border-radius: 0;

      &:only-child {
        border-top: none;
        padding: 0px;
      }

      &.single{
        padding-top: 0;
      }

      .card-header {
        background-color: transparent;
        border: 0;
        border-radius: 0;
        padding: 0;
        font-size: 1rem;
        min-width: 44px;
        min-height: 44px;
        //display: inline-flex;
        align-items: center;

        .btn {
          padding: 0;
          width: 100%;
          text-align: left;
          text-decoration: none;
          color: $black;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .text {
            width: 88%;
          }       
        }

        .card-title{
          white-space: normal;
          line-height: 1.5;
          width: 80%;
          float: left;
          text-align: left;
          color: black;
          margin: 0;
          overflow: auto;
        }

        &:focus{
          outline: 2px solid black ;
          margin: 2px;
        }

        &:focus:not(:focus-visible){
          outline: none;
        }
        
        &:focus-visible{
          outline: 2px solid black;
          margin: 2px;
        }
      }
      
      .card-body {
        padding: 10px 0 0 0;

        &.single-item {
          p {
            margin: 0;
            @include regular-text-spacing;
          }
        }

        H3 
        {
          a[href]
          {
            color: black;
            text-decoration:underline;
            min-width: 24px;
            min-height: 24px;
            display: inline-flex;
            align-items: center;
          }
        }

        p {
          margin: 0 30px 0 0;
          @include regular-text-spacing;
          a[href]
          {
            color: black;
            text-decoration:underline;
            min-width: 24px;
            min-height: 24px;
            display: inline-flex;
            align-items: center;
          }
        }
        
        ul{
          li{
            margin: 0 30px 0 0;
            font-size: em(15);
            //font-size: 15px;
            //line-height: calculateRem(22px);
            line-height: 1.5;
            letter-spacing: -0.4px;

            a[href]
            {
              color: black;
              text-decoration:underline;
              min-width: 24px;
              min-height: 24px;
              display: inline-flex;
              align-items: center;
            }
          }
        }

        ol{
          li{
            margin: 0 30px 0 0;
            font-size: em(15);
            //font-size: 15px;
            //line-height: calculateRem(22px);
            line-height: 1.5;
            letter-spacing: -0.4px;

            a[href]
            {
              color: black;
              text-decoration:underline;
              min-width: 24px;
              min-height: 24px;
              display: inline-flex;
              align-items: center;
            }
          }
        }
          
          table{
            tbody{
                tr{
                    td{
                        font-size: em(15);
                        //font-size: 15px;
                        a[href]
                        {
                          color: black;
                          text-decoration:underline;
                          min-width: 24px;
                          min-height: 24px;
                          display: inline-flex;
                          align-items: center;
                        }
                    }
                }
            }
          }

          img{
            width:100%;
            height:100%;
          }
      }
    }
  }
}

//Google Maps
.place{
  margin-top: 20px;
}

.accordion .more{
  &:after {
    content: "\e15b";
    float: right; 
    right: 5px;
    margin-top: 0;
    //color: $link;
    color: var(--iconcolor, $link);
    font-size: em(24);
    //font-size:24px;
    font-weight: bold;
  }
}

.accordion {
  .card-header.collapsed{

    &:after {
      content: "\e145";
      right: 5px;
      margin-top: 0;
      //color: $link;
      color: var(--iconcolor, $link);
    }
  }
}

.btn.btn-link{
  .more:after {
    content: "\e15b";
    float: right; 
    right: 5px;
    margin-top: 0;
    color: $link;
    font-size: em(16);
    //font-size:24px;
    color: #fff;
  }
}



.btn.btn-link.collapsed {
  .more:after{
    content: "\e145";
  right: 5px;
  margin-top: 0;
  color: #fff;
  }
  
}



.accordion {
  .card-header.single:after{
  color:blue($color: #000000) !important;
}}

#footerList{
    .card-header:after {
      content: none;
    }
  }

.fade-content {
  .someTitle{
    @include headline-3;
  }
}
 
.presentation-maincontent{
  .factbox{
    background-color: transparent;
    margin-left: -15px;
    padding: 0;
  }
}

.publicationtitle{
  font-weight: bold;
  
  span{
    vertical-align: middle;
  }
}