#form{

            .error {
                display: none;
                margin-left: 10px;
            }

            .error_show {
                //color: red;
                margin-left: 10px;
                font-size: 13px;
            }

            input.invalid {
                border: 2px solid red;
            }

            input.valid {
                border: 2px solid black;
            }
}
      
.editor{
    .seperator--green{
        border: none;
        background: none;
    }
}