@import '../utilities/rem-calculator';

// General
.filters {
  
  @include media-breakpoint-down(md) {
    /* width: 100%; */
    margin-bottom: 0px;
    flex: 0 0 0% !important;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 35px;
  }

  .subject {
    font-weight: bold;
    margin: 10px 0;
  }

  h2.subject {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    @include fontSize($base-size);
    line-height: 1.5;
    letter-spacing: -0.5px;
    line-break: loose;
    margin-block-end: 0;
    font-weight: bold;
    margin: 10px 0 !important;
    display: block;
  }

  legend.subject {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    @include fontSize($base-size);
    line-height: 1.5;
    letter-spacing: -0.5px;
    line-break: loose;
    margin-block-end: 0;
    font-weight: bold;
    margin: 10px 0 !important;
    display: block;
  }

  .list {
    list-style-type: none;
    padding: 0;

    .item {
      margin: 0 0 5px 0;
    }
  }
}

// Mobile
.no-scroll {
  overflow: hidden;
}

.filter-icon {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}



// Desktop
.filter-menu {
  @include media-breakpoint-down(md) {
    display: none;
    visibility: hidden;

    height: 100%;
    width: 320px;
    position: fixed;
    z-index: 1000000000;
    top: 0;
    right: -325px;
    background-color: $white;
    overflow-x: hidden;
    transition: 0.5s;
    padding: 16px 25px;
    box-shadow: 0px -5px 10px -5px $black;

    flex-direction: column;
    align-items: flex-end;
  }

  .mobile-close {
    @include small-text;
    color: $small-text-green;
    float: right;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    @include media-breakpoint-up(lg) {
      display: none;
      visibility: hidden;
    }
  }

  .filter-btn-clear {
    border-top: 1px solid $seperator-grey;
    margin: 25px 0 0 0;
    padding: 25px 0 0 0;

    @include media-breakpoint-down(md) {
      width: 100%;
      border: none;
    }
  }
  
  
  &.active {
    border-left: 1px solid $seperator-grey;
    box-shadow: 15px 0 20px 10px rgba($border, 0.2);
    display: block;

    .filters {
      @include media-breakpoint-down(md) {
        display: block;
      }
    }

    .filter-btn-close {
      position: absolute;
      top: 15px;
      right: 25px;

      .btn-icon {
        font-size: em(17);
        //font-size: 17px;
        color: $fki-dark-red;
      }
    }
  }

  .filters {
    .list {
      .item {
        display: block;

        .link{
          text-decoration: underline;
          color: black;
          font-size: em(16);
          //font-size: 15px;
          //line-height: calculateRem(22px);
          line-height: 1.5;
        }
      }
    }
  }
}

.MobileMenuClosed {
  right: -325px !important;
  display: none !important;
  visibility: hidden !important;
}

.MobileMenuOpened {
  right: 0px !important;
  display: block !important;
  visibility: visible !important;
}

.filterList{
  display: block;
}

.searchbar{
  float: left;
  @include media-breakpoint-up(md) {
    width:250px;
    float:left
  }
}

