.title-block {
  margin-top: 60px;

  .link-list {
    display: flex;
    margin-top: 0.2rem !important;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    .item {
      .link {
        margin-right: 25px;
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 0;
      }
    }
  }

  .image-placeholder {
    display: flex;
    justify-content: flex-end;

    @include media-breakpoint-down(md) {
      justify-content: flex-start;
    }

    .image {
      height: 100px;
    }
  }
}

.campaign-title .title-block{
  margin-top: 40px;
  
 .link-list {
    margin-bottom: 1.5rem;
 }
}
