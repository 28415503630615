@import '../utilities/rem-calculator';

.form-check-input {
  position: absolute;
  opacity: 0;
  //display: none;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    color: black;
  }

  // Box.
  & + label:before {
    content: "";
    margin-right: 15px;
    display: inline-block;
    vertical-align: text-top;
    background: white;
    //border: 1px solid #c6c6c6;
    border: 1px solid black;
    border-radius: 2px;
    box-sizing: border-box;
    height: 18px;
    width: 18px;
    float: left;
  }
  // Box hover
  &:hover + label:before {
    background: rgba($fki-dark-green, 1);
  }

  // Box focus
  &:focus + label:before {
    //box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    box-shadow: none;
    outline: none;
  }

  &:focus:not(:focus-visible) + label:before {
    //box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    box-shadow: none;
    outline: none;
  }

  &:focus-visible + label:before {
    box-shadow: 0 0 0 2px black;
  }

  // Box checked
  &:checked + label:before {
    background: $white;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: "";
    position: absolute;
    left: 4px;
    top: 5px;
    //color: $fki-dark-green;
    color: black;
    @extend %fa-icon;
    @extend .fas;
    content: fa-content($fa-var-check);    
    //@at-root: 11px;
    font-size: em(15);
  }

  &:checked + label:before {
    border: 1px solid black;
  }
}

.form-check-label {
  @include headline-3-manchet;
}

label{
  .listpagelist{
    @include regular-text-spacing;
    font-size: $base-size;
    margin-left: 33px;
  }

  p.listpagelist{
    @include media-breakpoint-down(md){
        //font-size: 15px !important;
        font-size: em(15) !important;
        //line-height: calculateRem(22px) !important;
        line-height: 1.5 !important;
    }
}
}
