@import "../utilities/rem-calculator";

.attachments {
  display: flex;
  align-items: center;
  border: 1px solid $seperator-grey;
  width:100%;
  margin-top: 20px;

  @include media-breakpoint-down(md) {
    width: 100%;
  }
  

  .item {
    display: flex;
    width: 100%;
    text-decoration: none;
    color: $black;
    padding: 11px;
    align-items: center;

    .icon {
      background-color: rgba($fki-dark-green, 0.1);
    }

    .icon,
    .logo {
      height: 57px;
      font-size: em(20);
      width: 57px;
      position: relative;

      .fa,
      .far {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      width: 100%;

      .title,
      .type {
        margin-left: 20px;
      }

      .title {
        //@include fontSize(17px);
        font-size: em(17);
        font-weight: bold;
        //line-height: calculateRem(17px);
        line-height: 1.5;
        letter-spacing: -0.8px;
      }

      .type {
        @include headline-3-manchet;
      }
    }
  }
}

.title{
  .logoTitle{
    vertical-align: text-bottom;
  }
}

.subtitle{
  .logoTitle{
    vertical-align: text-bottom;
  }
}
