@import '../utilities/rem-calculator';

.cookie-toggler {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10000000;
  transition: 0.5s;

  .btn {
    transition: 0.5s;
    box-shadow: 0px 0px 10px -5px $black;
    border-radius: 0px 15px 0px 0px;
    background-color: $fki-dark-green;
    border-color: $fki-dark-green;
    padding: 0.3rem 0.5rem 0.10rem 0.5rem;

    &:focus {
      outline: 0;
      //-webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      -webkit-box-shadow: 0 0 0 0.2rem black;
      //box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      box-shadow: 0 0 0 0.2rem black;
    }

    @include media-breakpoint-down(md) {
      padding: 0.2rem 0.3rem 0rem 0.2rem;

      .fa {
        //font-size: 20px;
        font-size: em(20);
      }
    }
  }
}

.foll-out-box {
  display: none;
  padding: 25px 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  z-index: 100000000000;

  .left-box-link {
    float: right;
    //margin-top: 15px;
  }

  .cookie-banner-left {
    background-color: $fki-dark-green;
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding-top: 15px;
    overflow: auto;
  
    h1 {
      //font-size: 38px;
      font-size: em(38);
      word-break: break-word;
      color: $white;
    }

    a {
      color: $white;
      min-width: 44px;
      min-height: 44px;
      display: flex;
      align-items: flex-start;
    }

    a.close-cookie-details {
      color: $white;
      min-width: 44px;
      min-height: 44px;
      display: flex;
      align-items: center;
    }

    a.close-cookie-details:hover {
      text-decoration: none;
    }

    .seperator{
      margin: 15px 0;
    }
  }
}

.cookiebanner {
  background-color: $fki-dark-green;
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 1110px;
  max-height: 90%;
  z-index: 1000000;
  overflow: auto;
  padding: 15px 15px 10px 15px;

  &.show {
    display: block;
  }

  &.hide {
    display: none;
  }

  .aboutcookies {
    position: absolute;
    left: 0;
    padding-left: 0px !important;
  }

  .banner-description {
    color: $white;
    margin-bottom: 0rem !important;

    p {
      margin-top: 0px;
      color: $white;
    }

    a {
      color: $white;
      text-decoration: underline;
    }
  }

  .seperator{
    margin-bottom: 0;
  }

  .info-link{
    min-width: 44px;
    min-height: 44px;
    display: inline-flex;
    align-items: flex-end;
  }

  .options {
    .cookies-list {
      margin: 0 0 0 15px;

      .description {
        color: $white;
      }

      .list {
        padding: 0px;
        list-style-type: none;
        margin-bottom: 0rem;

        li {
          color: $white;
        }
      }
    }


    a.details-link {
      color: $white;
      min-width: 44px;
      min-height: 44px;
      display: inline-flex;
      align-items: flex-end;

      span {
        //font-size: em(15);
        //font-size: 13px;
      }

    }

    .details {
      color: $white;
      font-size: em(13);
      //font-size: 13px;
    }
  }
}

/* .beredskabsstyrelsen {
  background-color: $fki-blue !important;
} */

.switch-container {
  display: inline-flex;
  align-items: flex-end;
  padding-bottom: 10px;
}


.switch {
  position: relative;
  display: inline-block;
  width: 43px;
  height: 24px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: "";
      height: 17px;
      width: 17px;
      left: 6px;
      border-radius: 50%;
      bottom: 4px;
      background-color: $white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    &.round {
      border-radius: 30px;
    }
  }

  input {
    &:checked + .slider {
      //background-color: #2196f3;
      background-color: white;
    }

    &:focus + .slider {
      //box-shadow: 0 0 1px #2196f3;
      box-shadow: none;
      outline: 3px solid black;
    }

    &:focus:not(:focus-visible) + .slider {
      //box-shadow: 0 0 1px #2196f3;
      box-shadow: none;
      outline: none;
    }

    &:focus-visible + .slider {
      //box-shadow: 0 0 1px #2196f3;
      box-shadow: 0 0 8px white;
      outline: 3px solid black;
    }

    &:checked + .slider:before {
      background-color: $fki-dark-green;
      left: -7px;
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    &:focus {
      outline: 2px solid black !important;
      //-webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      -webkit-box-shadow: 0 0 0 0.2rem black;
      //box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      box-shadow: 0 0 0 0.2rem black;
    }
  }
}

.icons-cookie-close{
  color: $white;
}

.icons-cookie{
  font-size: 2em;

  @include media-breakpoint-down(md) {
    font-size: 1.3em;
  }
}

.icons-chevron-right{
  vertical-align: bottom;
}

